<template>
  <header>
      <nav class="nav">
          <ul>
              <li><router-link class="link"  :to="{ name: 'WestkustView' }" >Westkust</router-link></li>
              <li><router-link class="link"  :to="{ name: 'OostendeView' }" >Oostende</router-link></li>
              <li><router-link class="link"  :to="{ name: 'OostkustView' }" >Oostkust</router-link></li>
              <li><router-link class="link"  :to="{ name: 'TrailerView' }" >Trailer</router-link></li>
          </ul>
      </nav>
  </header>
</template>

<script>
export default {
    name: 'BaseNavigation',
}
</script>

<style>
.link-disabled {
    opacity: .3;
    cursor:default;
}
</style>