<template>
  <BaseView :location="'eastCoast'" />
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import BaseView from "@/components/BaseView"


export default defineComponent({
  name: "OostkustView",
  components: {
   BaseView
  }
});
</script>
