<template>
  <div class="card" :class="noData ? 'card-empty' : 'card-gauge'">
    <h2>Windsnelheid</h2>
    <template v-if="!noData">
      <v-gauge
        :width="width"
        :options="options"
        :donut="false"
        :value="windSpeedAvg"
        :minValue="0"
        :maxValue="50"
      />

      <p>
        <b>{{ windSpeedAvg }} KT</b><br />
        <b v-if="windSpeedOneSec">GUST: {{ windSpeedOneSec }} KT</b>
      </p>
    </template>
    <EmptyCardBody v-else />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VGauge from "vgauge/src/VGauge.vue";
import EmptyCardBody from "@/components/EmptyCardBody.vue";

export default defineComponent({
  name: "WindSpeedCard",
  props: {
    windSpeedAvg: Number,
    windSpeedOneSec: Number,
    noData: Boolean,
  },
  components: {
    VGauge,
    EmptyCardBody,
  },
  data() {
    return {
      width: "400px",
      options: {
        angle: -0.19, // The span of the gauge arc
        animationSpeed: -1,
        // colorStart: "#6FADCF", // Colors
        // colorStop: "#8FC0DA", // just experiment with them
        // currval: 875,
        fontSize: 21,
        generateGradient: true,
        lineWidth: 0.2, // The line thickness
        radiusScale: 0.9, // Relative radius
        pointer: {
          length: 0.21, // // Relative to gauge radius
          strokeWidth: 0.049, // The thickness
          color: "#333333", // Fill color
        },

        staticZones: [
          { strokeStyle: "rgba(3, 94, 123, .75)", min: 0, max: 10 }, // Red from 100 to 130
          { strokeStyle: "rgb(132, 201, 220)", min: 10, max: 20 }, // Yellow
          { strokeStyle: "rgba(14, 173, 137, .75)", min: 20, max: 30 }, // Green
          { strokeStyle: "rgba(244, 201, 93, .75)", min: 30, max: 40 }, // Yellow
          { strokeStyle: "rgba(211, 78, 36, .75)", min: 40, max: 50 }, // Red
        ],
        // percentColors: [
        //   [0.0, "#a9d70b"],
        //   [0.5, "#f9c802"],
        //   [1.0, "#ff0000"],
        // ],
        staticLabels: {
          font: "16px 'Open Sans', sans-serif", // Specifies font
          labels: [0, 10, 20, 30, 40, 50], // Print labels at these values
          color: "#000000", // Optional: Label text color
          fractionDigits: 0, // Optional: Numerical precision. 0=round off.
        },

        limitMax: false, // If false, max value increases automatically if value > maxValue
        limitMin: false, // If true, the min value of the gauge will be fixed

        strokeColor: "#E0E0E0", // to see which ones work best for you
        highDpiSupport: true, // High resolution support
      },
    };
  },
  setup() {},
});
</script>

<style>
.gauge-title {
  display: none;
}

.card-gauge h2 + div {
    overflow: hidden;
}
</style>
