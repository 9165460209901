<template>
  <main>
    <section class="grid">
      <WindSpeedCard
        :windSpeedAvg="getLocation().windSpeed"
        :windSpeedOneSec="getLocation().windSpeedGust"
        :noData="getLocation().windSpeedNoGraph"
      />

      <WindDirectionCard
        :windDirection="getLocation().windDirection"
        :noData="getLocation().windDirectionNoGraph"
        :location="location"
      />

      <WaveCard
        :waveHeight="getWaterData.waveHeight"
        :wavePeriode="getWaterData.wavePeriode"
        :waveHeightIcon="getWaterData.waveIcon"
        :noData="getWaterData.waveIcon == null"
      />

      <WaterTemperatureCard
        :waterTemperature="getWaterData.waterTemperature"
        :waterTemperatureIcon="getWaterData.waterTemperatureIcon"
        :noData="getWaterData.waterTemperatureIcon == null"
      />
    </section>

    <section class="c-section--centered" v-if="isFetched">
      <h2>UV-index</h2>
      <a
        class="link link-external"
        href="https://www.sport.vlaanderen/smeerem"
        target="_blank"
        >Lees meer over de UV-index in de #smeerem campagne van Sport
        Vlaanderen</a
      >

      <div class="c-graph--uv js-graph-uv" :data-selected="setUvGraph()">
        <div class="c-uv__low" data-title="Laag risico">
          <div class="c-uv__grade" data-value="1">1</div>
          <div class="c-uv__grade" data-value="2">2</div>
        </div>
        <div class="c-uv__middle" data-title="Matig risico">
          <div class="c-uv__grade" data-value="4">3</div>
          <div class="c-uv__grade" data-value="4">4</div>
          <div class="c-uv__grade" data-value="5">5</div>
        </div>
        <div class="c-uv__high" data-title="Hoog risico">
          <div class="c-uv__grade" data-value="6">6</div>
          <div class="c-uv__grade" data-value="7">7</div>
        </div>
        <div class="c-uv__very-high" data-title="Zeer hoog risico">
          <div class="c-uv__grade" data-value="8">8</div>
          <div class="c-uv__grade" data-value="9">9</div>
          <div class="c-uv__grade" data-value="10">10</div>
        </div>
      </div>
    </section>

    <TidesCard
      v-if="isTidesDataFetched()"
      :isTidesDataFetched="isTidesDataFetched()"
      :tidesData="getLocation().tidesData"
    />

    <section>
      <h2>Windsnelheid en -richting van de afgelopen 6 uur</h2>
      <div class="grid">
        <WindSpeedLastHourCard
          v-if="isFetched"
          :isFetched="isFetched"
          :windSpeedData="getLocation().windSpeedLastHourData"
          :timeStamps="getLocation().windSpeedLastHourDataTimeStamps"
        />
        <WindDirectionLastHourCard
          v-if="isFetched"
          :isFetched="isFetched"
          :windDirectionData="getLocation().windDirectionLastHourData"
          :timeStamps="getLocation().windDirectionLastHourDataTimeStamps"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import WindSpeedCard from "@/components/WindSpeedCard";
import WindDirectionCard from "@/components/WindDirectionCard";
import WaveCard from "@/components/WaveCard";
import WaterTemperatureCard from "@/components/WaterTemperatureCard";
import TidesCard from "@/components/TidesCard.vue";
import WindSpeedLastHourCard from "@/components/WindSpeedLastHourCard";
import WindDirectionLastHourCard from "@/components/WindDirectionLastHourCard";

import { useWindStore } from "@/store/useWindStore.js";
import { mapActions, mapState } from "pinia";

export default defineComponent({
  name: "BaseView",
  components: {
    WindSpeedCard,
    WindDirectionCard,
    WaveCard,
    WaterTemperatureCard,
    TidesCard,
    WindSpeedLastHourCard,
    WindDirectionLastHourCard,
  },
  props: {
    location: String,
  },
  data(props) {
    return {
      refetching: null,
      myLocation: props.location,
    };
  },
  computed: {
    ...mapState(useWindStore, ["getOstend"]),
    ...mapState(useWindStore, ["getEastCoast"]),
    ...mapState(useWindStore, ["getWestCoast"]),
    ...mapState(useWindStore, ["getWaterData"]),
    ...mapState(useWindStore, ["getUv"]),
    ...mapState(useWindStore, ["isFetched"]),
    ...mapState(useWindStore, ["isFetchedWestCoastTidesData"]),
    ...mapState(useWindStore, ["isFetchedOstendTidesData"]),
    ...mapState(useWindStore, ["isFetchedEastCoastTidesData"]),
  },
  methods: {
    ...mapActions(useWindStore, ["fetchData"]),
    ...mapActions(useWindStore, ["fetchTidesData"]),

    reFetchData() {
      // TODO: fetch when browser /tab is active!
      this.refetching = setInterval(() => {
        this.fetchData();
        this.fetchTidesData();
      }, 10000);
    },
    async assignSpeedLastHour() {
      this.windSpeedLastHourData = await this.getLocation()
        .windSpeedLastHourData;
    },
    async assignTidesData() {
      this.tidesData = await this.getLocation().tidesData;
    },
    async setUvGraph() {
      const imgUp = new Image();
      imgUp.src = require("@/assets/img/icon-up.svg");
      const sun = new Image();
      sun.src = require("@/assets/img/sun.svg");

      let valueUV = await this.getUv;
      valueUV = valueUV < 1 ? 1 : valueUV > 10 ? 10 : valueUV;

      const pointerHtml = `<div class="c-uv__pointer js-pointer-uv" style="left: ${
        50 + (valueUV - Math.floor(valueUV)) * 100
      }%;"><img src="${imgUp.src}" alt="" /><img src="${
        sun.src
      }" alt="" /></div>`;
      const valueHtml = document.querySelector(
        `[data-value="${Math.floor(valueUV)}"]`
      );

      if (document.querySelector(".js-pointer-uv")) {
        document.querySelector(".js-pointer-uv").remove();
        valueHtml.insertAdjacentHTML("beforeend", pointerHtml);
      } else {
        console.log(
          "first time",
          document.querySelector(".js-pointer-uv"),
          this.getUv
        );

        valueHtml.insertAdjacentHTML("beforeend", pointerHtml);
      }
    },
    isTidesDataFetched() {
      const l = this.myLocation;
      let fetched = false;

      switch (l) {
        case "ostend":
          fetched = this.isFetchedOstendTidesData;
          break;
        case "eastCoast":
          fetched = this.isFetchedEastCoastTidesData;
          break;
        case "westCoast":
          fetched = this.isFetchedWestCoastTidesData;
          break;
        default:
          break;
      }

      return fetched;
    },
    getLocation() {
      const l = this.myLocation;
      let myLocation;
      switch (l) {
        case "ostend":
          myLocation = this.getOstend;
          break;
        case "eastCoast":
          myLocation = this.getEastCoast;
          break;
        case "westCoast":
          myLocation = this.getWestCoast;
          break;
        default:
          break;
      }

      return myLocation;
    },
  },
  created() {
    this.fetchData();
    this.fetchTidesData();
  },
  async mounted() {
    this.reFetchData();
    // await this.assignSpeedLastHour();
  },
  beforeDestroy() {
    clearInterval(this.refetching);
  },
});
</script>

<style>
.c-section--centered {
  text-align: center;
}

.c-graph--uv,
.c-graph--uv > * {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 24px;
  margin-bottom: 48px;
}

.c-uv__grade {
  width: calc(100vw / 11);
  max-width: calc(1200px / 11);
  padding: 4px 0;
  color: #fff;
  font-weight: 900;
  text-shadow: 0 0px 3px rgba(10, 10, 10, 0.5);
  line-height: 1.35;
  cursor: default;
  position: relative;
}

.c-graph--uv *:first-child > .c-uv__grade:first-of-type {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.c-graph--uv *:last-child > .c-uv__grade:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.c-uv__low::before,
.c-uv__middle::before,
.c-uv__high::before,
.c-uv__very-high::before {
  content: attr(data-title);
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-size: 12px;
  font-weight: 300;
}

.c-uv__low .c-uv__grade {
  background-color: #5fc7b2;
}

.c-uv__middle .c-uv__grade {
  background-color: #f1d996;
}

.c-uv__high .c-uv__grade {
  background-color: #ecbb8b;
}

.c-uv__very-high .c-uv__grade {
  background-color: #dd8b71;
}

.c-uv__pointer {
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative; */

  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  /* left: calc(50% + 50%); */
}

.c-uv__pointer > img:first-child {
  width: 16px;
  margin-top: 8px;
  margin-bottom: 4px;
}
</style>
