import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import VueRouter from "vue-router";
import { createPinia, PiniaVuePlugin } from 'pinia'

import { Chart, registerables } from "chart.js";

import VComp from "@vue/composition-api";

Chart.register(...registerables);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VComp);
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
