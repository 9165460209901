<template>
  <div class="card" :class="noData ? 'card-empty' : 'card-wind'">
    <h2>Windrichting</h2>

    <template v-if="!noData">
      <div
        class="wind-direction"
        :class="{
          'wind-direction-eastcoast': location  === 'eastCoast',
          'wind-direction-ostend': location === 'ostend',
          'wind-direction-westcoast': location === 'westCoast',
        }"
      >
        <div class="wind-points">
          <span class="wind-point wind-point-n">N</span>
          <span class="wind-point wind-point-e">E</span>
          <span class="wind-point wind-point-s">S</span>
          <span class="wind-point wind-point-w">W</span>
        </div>
        <img
          src="@/assets/svgstore/wind-pointer.svg"
          alt=""
          :style="`transform: rotate(${windDirection}deg) translate(-50%, -50%);`"
        />
      </div>
      <p>
        <b>{{ degToCompass(windDirection) }} {{ windDirection }}°</b>
      </p>
    </template>
    <EmptyCardBody v-else />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import EmptyCardBody from "@/components/EmptyCardBody.vue";

export default defineComponent({
  name: "WindDirectionCard",
  props: {
    windDirection: Number,
    noData: Boolean,
    location: String,
  },
  components: {
    EmptyCardBody,
  },
  methods: {
    degToCompass: (num) => {
      var val = Math.floor(num / 22.5 + 0.5);
      var arr = [
        "N", // 0 === 360
        "NNE",
        "NE", // 45
        "ENE",
        "E", // 90
        "ESE",
        "SE", // 135
        "SSE",
        "S", // 180
        "SSW",
        "SW", // 225
        "WSW",
        "W", // 270
        "WNW",
        "NW", // 315
        "NNW",
      ];
      return arr[val % 16];
    },
  },
  data() {
    return {};
  },
  setup() {},
});
</script>

<style></style>
