<template>
  <div
    class="card card-secondary"
    :class="{ 'card-empty': !isFetched || graphIsEmpty() }"
  >
    <h3 v-if="isTrailer">Windrichting afgelopen uur</h3>
    <h3 v-else>Windrichting afgelopen 6 uur</h3>

    <LineChart
      ref="chartDirection"
      v-if="loaded && !graphIsEmpty()"
      :time-stamps="timeStamps"
      :chart-data="chartData"
      :chart-options="chartOptions"
      :height="200"
    />

    <EmptyCardBody v-else />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import EmptyCardBody from "@/components/EmptyCardBody.vue";

import { Line as LineChart } from "vue-chartjs/legacy";
import { defaults } from "chart.js";

export default defineComponent({
  name: "WindDirectionLastHour",
  components: { LineChart, EmptyCardBody },
  props: {
    windDirectionData: Array,
    timeStamps: Array,
    isFetched: Boolean,
    isTrailer: Boolean,
  },
  data: (props) => ({
    loaded: props.isFetched,
    chartData: {},
    chartOptions: {},
    timeStampOptions: { hour: "2-digit", minute: "2-digit" },
  }),
  methods: {
    getStartAndFinalTimestamps() {
      const finalTime = new Date(this.timeStamps[this.timeStamps.length - 1]);
      return `${this.getInitialTimestamp()} - ${finalTime.toLocaleTimeString(
        [],
        this.timeStampOptions
      )}`;
    },
    getInitialTimestamp() {
      const initialTime = new Date(this.timeStamps[0]);
      return initialTime.toLocaleTimeString([], this.timeStampOptions);
    },
    graphIsEmpty() {
      return this.timeStamps.length === 0;
    },
  },
  mounted() {
    defaults.font.family = "Oswald, sans-serif";
    defaults.font.size = 16;
    defaults.font.color = "#000";

    this.chartOptions = {
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          position: "none",
        },
        title: {
          display: true,
          position: "bottom",
          text: this.getStartAndFinalTimestamps(),
          color: "#333",
          padding: {
            top: 20,
          },
          font: {
            size: 16,
          },
        },
      },
      scales: {
        y: {
          position: "left",
          min: 0,
          max: 360,
          grid: {
            drawBorder: false,
            tickColor: "#84C9DC",
            tickBorderDash: [0, 1],
          },
          ticks: {
            beginAtZero: true,

            min: 0,
            max: 360,
            stepSize: 45,
            color: "#333",
            font: {
              family: "Oswald, sans-serif",
              size: 14,
            },

            callback: function (value) {
              //   console.log(value, index, ticks);
              let direction;
              switch (value) {
                case 0:
                case 360:
                  direction = "N";
                  break;
                case 45:
                  direction = "NE";
                  break;
                case 90:
                  direction = "E";
                  break;
                case 135:
                  direction = "SE";
                  break;
                case 180:
                  direction = "S";
                  break;
                case 225:
                  direction = "SW";
                  break;
                case 270:
                  direction = "W";
                  break;
                case 315:
                  direction = "NW";
                  break;
                default:
                  break;
              }
              return direction;
            },
          },
        },
        x: {
          display: false,
        },
      },
    };

    this.chartData = {
      labels: this.windDirectionData,
      datasets: [
        {
          borderColor: "#84C9DC",
          cubicInterpolationMode: "default",
          data: this.windDirectionData,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
      ],
    };

  },
   updated() {
    this.$nextTick(() => {
    //   if (!this.graphIsEmpty) {
      const chartInstance = this.$refs.chartDirection;
      setTimeout(() => {
        chartInstance.updateChart();
      }, 1000);
    // }
    });
  },
});
</script>

<style scoped></style>
