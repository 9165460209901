<template>
  <div>
    <BaseNavigation />
    <router-view />
    <FooterNavigation />
  </div>
</template>

<script>
import BaseNavigation from "@/components/BaseNavigation";
import FooterNavigation from "@/components/FooterNavigation";
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "App",
  components: {
    BaseNavigation,
    FooterNavigation,
  },
  data() {
    return {
      info: null,
      // ZDH = Zeebrugge
      // OST = Oostende
      // NP7 = Nieuwpoort

      // WRS", // gemiddelde windrichting in deg
      // WVC", // gemiddelde windsnelheid op 10m in m/
      // zeebrugge, nieuwpoort, oostende

      // "ZDI  > HUM", //relatieve vochtigheid in %
      // "ZDI  > LDR", //luchdruk in hPa
      // "ZDI  > NSI", //neerslag in mm
      // "ZDI  > TLU", //luchttemperatuur in °C
      // "ZDI  > WRS", //gemiddelde windrichting in deg
      // "ZDI  > WC1", //max 1 sec windstoot op 10m in m/s
      // "ZDI  > WVC", //gemiddelde windsnelheid op 10m in m/s
      // "ZDI  > ZON", //zonnestraling in W/m2

      // "OST  > GH1", //1O% hoogstegolven in cm
      // "OST  > GHA", //golfhoogte in cm
      // "OST  > GTZ", //gemiddelde golfperiode in s
      // "OST  > HLF", //hoogte deining in cm
      // "OST  > RHF", //richting golven met periode tussen 2 en 5 seconden in deg
      // "OST  > RLF", //richting golven met periode > 10s in deg
      // "OST  > TZW" //zeewatertemperatuur in °
    };
  },
  mounted() {},
  async created() {
    try {
      var config = {
        method: "get",
        url: "https://s3.eu-west-1.amazonaws.com/com.sportamundi.wwsv.meteo/meteo.json",
      };
      const res = await axios(config);

      this.info = res.data;
    } catch (error) {
      console.log(error);
    }
  },

  setup() {},
});
</script>

<style>
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("./assets/fonts/open-sans-v28-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v28-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("./assets/fonts/open-sans-v28-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v28-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./assets/fonts/oswald-v47-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/oswald-v47-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-600 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("./assets/fonts/oswald-v47-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/oswald-v47-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root {
  --font-family-primary: "Open Sans", sans-serif;
  --font-family-secondary: "Oswald", sans-serif;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;

  --color-primary-light: rgba(132, 201, 220, 0.14);
  --color-primary: rgba(132, 201, 220, 1);
  --color-primary-dark: rgba(47, 141, 160, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-neutral-xx-light: rgba(253, 253, 253, 1);
  --color-neutral-x-light: rgba(244, 244, 244, 1);
  --color-neutral-light: rgba(224, 224, 224, 1);
  --color-neutral: rgba(151, 151, 151, 1);
  --color-neutral-dark: rgba(51, 51, 51, 1);
  /* --color-neutral-dark: rgba(33, 37, 41, 1); */

  --spacing-mini: 2px;
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;
  --spacing-huge: 64px;

  --aspect-ratio: 4/3;
}

html {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-semibold);
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  margin-bottom: var(--spacing-medium);
}

header,
main,
footer {
  padding: 0 var(--spacing-large);
  max-width: 1200px;
  margin: auto;
}

section:not(section.video) {
  margin-bottom: var(--spacing-huge);
}

.nav {
  margin-bottom: var(--spacing-large);
}

.nav > ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav > ul li {
  padding: var(--spacing-small) var(--spacing-medium);
}

.link {
  color: var(--color-neutral-dark);
  padding: 0 0 var(--spacing-tiny);
  display: inline-block;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: color 0.2s ease-in;
}

.link:hover:not(.router-link-active, .link-disabled) {
  opacity: 0.7;
}

.router-link-active {
  border-bottom-color: var(--color-primary);
}

.link-external {
  color: var(--color-primary-dark);
  position: relative;
  padding-right: calc(var(--spacing-medium) * 1.5);
}

.link-external::after {
  content: "";
  position: absolute;
  width: var(--spacing-medium);
  height: var(--spacing-medium);
  background-image: url("@/assets/svgstore/arrow-top-right.svg");
  top: 50%;
  transform: translateY(-50%);
  right: var(--spacing-tiny);
  transition: top 0.15s ease-in-out, right 0.15s ease-in-out;
}

.link-external:hover::after,
.link-external:focus::after {
  top: calc(50% - var(--spacing-mini));
  right: var(--spacing-mini);
}

.grid {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(360px, 2fr));
  gap: var(--spacing-large);
  /* grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr); */
  /* grid-template-rows: minmax(200px, 1fr) minmax(200px, 1fr); */
}

.card {
  background: var(--color-primary-light);
  border-radius: var(--spacing-small);
  min-height: 200px;
  padding: var(--spacing-large) var(--spacing-medium);
}

.card-secondary {
  background: var(--color-white);
  border-radius: 0;
}

.card-empty {
  border: 1px dashed rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: #fcfcfc */
  background: var(--color-neutral-xx-light);
}

.card-empty .card-body {
  min-height: 200px;
}

.card-body {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card > p {
  font-family: var(--font-family-secondary);
  font-size: 20px;
  text-align: center;
}

.card.card-empty p.empty-message {
  font-family: var(--font-family-primary);
  font-size: 16px;
  color: var(--color-neutral);
  margin-top: var(--spacing-medium);
}

.card > h2 {
  margin-bottom: var(--spacing-large);
}

.card:first-of-type:last-of-type {
  min-height: 300px;
}

.card-gauge > div:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: var(--spacing-huge) 0 var(--spacing-large);
}

.gauge-title {
  position: absolute;
  top: calc(50% + 64px);
  transform: translateY(-50%);
}

.wind-direction {
  background-image: url("@/assets/svgstore/wind-direction.svg");
  width: 200px;
  height: 200px;
  margin: var(--spacing-huge) auto;
  border-radius: 50%;
  position: relative;
  background-position: center;
  /* box-shadow: 0 0 10px -4px rgba(100, 100, 100, 0.5); */
  background-size: 280px;
}

.wind-direction-eastcoast {
  background-image: url("@/assets/svgstore/65-deg.svg");
}

.wind-direction-ostend {
  background-image: url("@/assets/svgstore/50-deg.svg");
}

.wind-direction-westcoast {
  background-image: url("@/assets/svgstore/60-deg.svg");
}

.wind-direction > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 0 100px;
  transform: rotate(290deg) translate(-50%, -50%);
  padding-top: var(--spacing-small);
}

.wind-points {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wind-point {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wind-point-n,
.wind-point-s {
  left: 50%;
  transform: translateX(-50%);
}

.wind-point-e,
.wind-point-w {
  top: 50%;
  transform: translateY(-50%);
}

.wind-point-n {
  top: calc(var(--spacing-medium) * -1.5);
}

.wind-point-s {
  bottom: calc(var(--spacing-medium) * -1.5);
}

.wind-point-e {
  right: calc(var(--spacing-medium) * -1.5);
}

.wind-point-w {
  left: calc(var(--spacing-medium) * -1.5);
}

.wave-height,
.temperature {
  margin: var(--spacing-large) auto;
}

.wave-height > img,
.temperature > img {
  margin: auto;
  filter: drop-shadow(1px 2px 5px rgba(100, 100, 100, 0.1));
}

.card-wave-time {
  display: flex;
  flex-wrap: wrap;
  min-height: 0 !important;
  padding-left: 0;
  padding-right: 0;
}

.card-wave-time > div {
  flex: 1 0 100%;
}

canvas#line-chart {
  max-width: 100%;
}

/* Trailer */
.aspect-ratio > :first-child {
  width: 100%;
}

.aspect-ratio > img {
  height: auto;
}

.aspect-ratio {
  position: relative;
}

.aspect-ratio::before {
  content: "";
  display: block;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.aspect-ratio > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.aspect-ratio::before {
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

@media screen and (min-width: 679px) {
  :root {
    --aspect-ratio: 3/1.75;
  }
}

@media screen and (max-width: 800px) {
      .grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 2fr));
    gap: var(--spacing-small);
  }
}

@media screen and (max-width: 640px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    gap: var(--spacing-small);
  }

  section:not(section.video) {
    margin-bottom: var(--spacing-large);
  }

  .card {
    /* padding: var(--spacing-medium); */
    padding: var(--spacing-small);
  }
}

@media screen and (max-width: 420px) {
  :root {
    /* --aspect-ratio: 3/1.75; */
  }

  .grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
    gap: var(--spacing-small);
  }

  section:not(section.video) {
    margin-bottom: var(--spacing-large);
  }

  section:last-child {
    margin-top: var(--spacing-huge);
  }

  section:last-child .grid {
    gap: 0;
  }

  .card-gauge > div:first-of-type {
    margin: var(--spacing-medium) 0 var(--spacing-small);
  }

  .card > h2 {
    margin-bottom: var(--spacing-medium);
  }

  .wind-direction {
    margin: var(--spacing-large) auto;
  }

  .c-uv__grade {
    width: calc(280px / 11) !important;
    /* max-width: calc(340px / 11); */
  }

  .c-uv__low::before,
  .c-uv__middle::before,
  .c-uv__high::before,
  .c-uv__very-high::before {
    top: -40px !important;
  }

  .c-graph--uv,
  .c-graph--uv > * {
    margin-bottom: 32px !important;
  }

  .card-col {
    flex-direction: row !important;
  }

  dl:nth-child(2) {
    margin-bottom: 8px;
  }
}
</style>
