import { defineStore } from "pinia";
import axios from "axios";

const convertToNumbersWith2Decimals = (num) => {
  // https://stackoverflow.com/a/6134070/1964399
  return Number((Math.round(num * 100) / 100).toFixed(2));
};

export const useWindStore = defineStore("windStore", {
  state: () => {
    return {
      locations: {
        westCoast: {
          name: `nieuwpoort`,
          windSpeed: null,
          windSpeedGust: null,
          windSpeedNoGraph: true,
          windDirection: null,
          windDirectionNoGraph: true,
          windDirectionLastHourData: null,
          windDirectionLastHourDataTimeStamps: null,
          windSpeedLastHourData: null,
          windSpeedLastHourDataTimeStamps: null,
          tidesData: null,
        },
        ostend: {
          name: `oostende`,
          windSpeed: null,
          windSpeedGust: null,
          windSpeedNoGraph: true,
          windDirection: null,
          windDirectionNoGraph: true,
          windDirectionLastHourData: null,
          windDirectionLastHourDataTimeStamps: null,
          windSpeedLastHourData: null,
          windSpeedLastHourDataTimeStamps: null,
          tidesData: null,
        },
        eastCoast: {
          name: `zeebrugge`,
          windSpeed: null,
          windSpeedGust: null,
          windSpeedNoGraph: true,
          windDirection: null,
          windDirectionNoGraph: true,
          windDirectionLastHourData: null,
          windDirectionLastHourDataTimeStamps: null,
          windSpeedLastHourData: null,
          windSpeedLastHourDataTimeStamps: null,
          tidesData: null,
        },
        trailer: {
          name: `windkar`,
          windSpeed: null,
          windSpeedGust: null,
          windSpeedNoGraph: true,
          windDirection: null,
          windDirectionNoGraph: true,
          windSpeedLastHourData: null,
          windSpeedLastHourDataTimeStamps: null,
          windDirectionLastHourData: null,
          windDirectionLastHourDataTimeStamps: null,
        },
      },

      golfwater: {
        waveHeight: null,
        wavePeriode: null,
        waveIcon: null,
        waterTemperature: null,
        waterTemperatureIcon: null,
      },

      uv: null,
      fetching: false,
      fetched: false,

      fetchingWestCoastTidesData: false,
      fetchingOstendTidesData: false,
      fetchingEastCoastTidesData: false,

      fetchedWestCoastTidesData: false,
      fetchedOstendTidesData: false,
      fetchedEastCoastTidesData: false,
    };
  },
  getters: {
    isFetching(state) {
      return state.fetching;
    },
    isFetched(state) {
      return state.fetched;
    },
    isFetchedWestCoastTidesData(state) {
      return state.fetchedWestCoastTidesData;
    },
    isFetchedOstendTidesData(state) {
      return state.fetchedOstendTidesData;
    },
    isFetchedEastCoastTidesData(state) {
      return state.fetchedEastCoastTidesData;
    },
    getLocations(state) {
      return state.locations;
    },
    getEastCoast(state) {
      return state.locations.eastCoast;
    },
    getWestCoast(state) {
      return state.locations.westCoast;
    },
    getOstend(state) {
      return state.locations.ostend;
    },
    getWaterData(state) {
      return state.golfwater;
    },
    getTrailer(state) {
      return state.locations.trailer;
    },
    getUv(state) {
      return state.uv;
    },
  },

  actions: {
    async fetchTidesData() {
      // console.log('fetching tides');
      this.fetchingWestCoastTidesData = true;
      this.fetchingOstendTidesData = true;
      this.fetchingEastCoastTidesData = true;
      const locations = ["nieuwpoort", "oostende", 
      "zeebrugge"
    ];

      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        const response = await axios.get(
          `https://s3.eu-west-1.amazonaws.com/com.sportamundi.wwsv.meteo/${location}Tides.json`
        );

        try {
          const result = response.data;
          if (location === "nieuwpoort") {
            this.locations.westCoast.tidesData = result?.nieuwpoort;
            this.fetchedWestCoastTidesData = true;
          } else if (location === "oostende") {
            this.locations.ostend.tidesData = result?.oostende;
            this.fetchedOstendTidesData = true;
          } else {
            this.locations.eastCoast.tidesData = result?.zeebrugge;
            this.fetchedEastCoastTidesData = true;
          }
        } catch (error) {
          console.log(error);
        }
      }

      // console.log(this.locations.westCoast.tidesData);

      this.fetchingWestCoastTidesData = false;
      this.fetchingOstendTidesData = false;
      this.fetchingEastCoastTidesData = false;
    },
    async fetchData() {
      this.fetching = true;
      const response = await axios.get(
        "https://s3.eu-west-1.amazonaws.com/com.sportamundi.wwsv.meteo/meteo.json"
      );

      //   console.log("fetching", response);

      try {
        const result = response.data;
        // let speedPoints

        for (const key in result) {
          switch (key) {
            case "uv":
              // console.log('is uv', result[key]);
              this.uv = result[key] ? result[key] : null;
              //   console.log('after', this.uv);
              break;
            case "zeebruggeWVC":
              this.locations.eastCoast.windSpeedLastHourData = [
                ...result[key]?.map((obj) => obj?.Value * 1.943844),
              ];
              this.locations.eastCoast.windSpeedLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "zeebruggeWRS":
              this.locations.eastCoast.windDirectionLastHourData = [
                ...result[key]?.map((obj) => obj?.Value),
              ];
              this.locations.eastCoast.windDirectionLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "oostendeWVC":
              this.locations.ostend.windSpeedLastHourData = [
                ...result[key]?.map((obj) => obj?.Value * 1.943844),
              ];
              this.locations.ostend.windSpeedLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "oostendeWRS":
              this.locations.ostend.windDirectionLastHourData = [
                ...result[key]?.map((obj) => obj?.Value),
              ];
              this.locations.ostend.windDirectionLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "nieuwpoortWVC":
              this.locations.westCoast.windSpeedLastHourData = [
                ...result[key]?.map((obj) => obj?.Value * 1.943844),
              ];
              this.locations.westCoast.windSpeedLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "nieuwpoortWRS":
              this.locations.westCoast.windDirectionLastHourData = [
                ...result[key]?.map((obj) => obj?.Value),
              ];
              this.locations.westCoast.windDirectionLastHourDataTimeStamps = [
                ...result[key]?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "windkar":
              this.locations.trailer.windSpeed = convertToNumbersWith2Decimals(
                result[key]?.wind?.speed
              );
              this.locations.trailer.windDirection =
                convertToNumbersWith2Decimals(result[key]?.wind?.direction);
              this.locations.trailer.windSpeedNoGraph =
                result[key]?.wind?.speed == null;
              this.locations.trailer.windDirectionNoGraph =
                result[key]?.wind?.direction == null;
              this.locations.trailer.windSpeedLastHourData = [
                ...result['windkarWVC']?.map((obj) => obj?.Value),
              ];
              this.locations.trailer.windSpeedLastHourDataTimeStamps = [
                ...result['windkarWVC']?.map((obj) => obj?.Timestamp),
              ];
              this.locations.trailer.windDirectionLastHourData = [
                ...result['windkarWRS']?.map((obj) => obj?.Value),
              ];
              this.locations.trailer.windDirectionLastHourDataTimeStamps = [
                ...result['windkarWRS']?.map((obj) => obj?.Timestamp),
              ];
              break;
            case "golfwater":
              this.golfwater.waveHeight = result[key]?.gha?.Value;
              this.golfwater.wavePeriode = result[key]?.gtz?.Value;

              this.golfwater.waveIcon =
                result[key]?.gha?.Value == null
                  ? null
                  : result[key]?.gha?.Value <= 20
                  ? require("@/assets/svgstore/wave-flat.svg")
                  : result[key]?.gha?.Value <= 50 && result[key]?.gha?.Value > 20
                  ? require("@/assets/svgstore/wave-low.svg")
                  : result[key]?.gha?.Value <= 100 && result[key]?.gha?.Value > 50
                  ? require("@/assets/svgstore/wave-medium.svg")
                  : require("@/assets/svgstore/wave-high.svg");

              this.golfwater.waterTemperature = result[key]?.tzw?.Value;
              this.golfwater.waterTemperatureIcon =
                result[key]?.tzw?.Value == null
                  ? null
                  : result[key]?.tzw?.Value < 10
                  ? require("@/assets/svgstore/temperature-low.svg")
                  : result[key]?.tzw?.Value >= 10 && result[key]?.tzw?.Value < 15
                  ? require("@/assets/svgstore/temperature-medium.svg")
                  : require("@/assets/svgstore/temperature-high.svg");
              break;
            default:
              for (const loc in this.locations) {
                // console.log('this', loc);
                if (Object.hasOwnProperty.call(this.locations, loc)) {
                  //   console.log(this.locations[loc]);
                  if (this.locations[loc].name === `${key}`) {
                    if ("wvc" in result[key]) {
                      const windSpeedKt = convertToNumbersWith2Decimals(
                        result[key]?.wvc?.Value * 1.943844
                      );
                      this.locations[loc].windSpeed = windSpeedKt;
                      this.locations[loc].windSpeedNoGraph =
                        result[key]?.wvc?.Value == null;
                    }

                    // wc1
                    if ("wc1" in result[key] || "wc3" in result[key]) {
                      const wc = result[key]?.wc1 || result[key]?.wc3;
                      const windSpeedGustKt = convertToNumbersWith2Decimals(
                        wc?.Value * 1.943844
                      );
                      this.locations[loc].windSpeedGust = windSpeedGustKt;
                    }

                    // wrs
                    if ("wrs" in result[key]) {
                      this.locations[loc].windDirection = result[key]?.wrs?.Value;
                      this.locations[loc].windDirectionNoGraph =
                        result[key]?.wrs?.Value == null;
                    }
                  }
                }
              }
              break;
          }
        }
        this.fetched = true;
      } catch (error) {
        // alert(error);
        console.log(error);
      }
      this.fetching = false;
    },
  },
});
