import VueRouter from "vue-router";
import OostkustView from "@/views/OostkustView.vue";
import OostendeView from "@/views/OostendeView.vue";
import WestkustView from "@/views/WestkustView.vue";
import TrailerView from "@/views/TrailerView.vue";

const routes = [
  {
    path: "/",
    redirect: "/oostkust", 
  },
  {
    path: "/westkust", // Nieuwpoort
    name: "WestkustView", 
    component: WestkustView,
  },
  {
    path: "/oostende",
    name: "OostendeView",
    component: OostendeView,
  },
  {
    path: "/oostkust", // Zeebrugge
    name: "OostkustView", 
    component: OostkustView,
  },
  {
    path: "/trailer",
    name: "TrailerView",
    component: TrailerView,
  },
];

const router = new VueRouter({ 
    mode: 'history',
    routes });


export default router;
