<template>
  <BaseView :location="'ostend'" />
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import BaseView from "@/components/BaseView"


export default defineComponent({
  name: "OostendeView",
  components: {
   BaseView
  }
});
</script>
