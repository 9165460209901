<template>
  <footer class="nav">
    <ul>
      <li>
        <a
          class="link link-external"
          href="https://windfinder.com"
          target="_blank"
          >Windfinder</a
        >
      </li>
      <li>
        <a class="link link-external" href="https://windguru.cz" target="_blank"
          >Windguru</a
        >
      </li>
      <li>
        <a
          class="link link-external"
          href="https://www.kustweerbericht.be"
          target="_blank"
          >Kustweerbericht</a
        >
      </li>
      <li>
        <a
          class="link link-external"
          href="https://odnature.naturalsciences.be/mumm/"
          target="_blank"
          >MUMM</a
        >
      </li>
      <li>
        <a
          class="link link-external"
          href="https://www.windy.com"
          target="_blank"
          >Windy</a
        >
      </li>
    </ul>
  </footer>
</template>

<script>
export default {};
</script>
