<template>
  <main>
    <section class="grid">
      <WindSpeedCard
        :windSpeedAvg="getTrailer.windSpeed"
        :windSpeedOneSec="getTrailer.windSpeedGust"
        :noData="getTrailer.windSpeedNoGraph"
      />

      <WindDirectionCard
        :windDirection="getTrailer.windDirection"
        :noData="getTrailer.windDirectionNoGraph"
      />

      <WaveCard
        :waveHeight="getWaterData.waveHeight"
        :wavePeriode="getWaterData.wavePeriode"
        :waveHeightIcon="getWaterData.waveIcon"
        :noData="getWaterData.waveIcon == null"
      />

      <WaterTemperatureCard
        :waterTemperature="getWaterData.waterTemperature"
        :waterTemperatureIcon="getWaterData.waterTemperatureIcon"
        :noData="getWaterData.waterTemperatureIcon == null"
      />
    </section>

    <!-- <TidesCard :data="tidesData" /> -->

    <section>
      <h2>Windsnelheid en -richting van het afgelopen uur</h2>
      <div class="grid">
        <WindSpeedLastHourCard
          v-if="isFetched"
          :isFetched="isFetched"
          :windSpeedData="getTrailer.windSpeedLastHourData"
          :timeStamps="getTrailer.windSpeedLastHourDataTimeStamps"
          isTrailer
        />
        <WindDirectionLastHourCard
          v-if="isFetched"
          :isFetched="isFetched"
          :windDirectionData="getTrailer.windDirectionLastHourData"
          :timeStamps="getTrailer.windDirectionLastHourDataTimeStamps"
          isTrailer
        />
      </div>
    </section>

    <section class="video aspect-ratio">
      <iframe
        src="https://assets.citymesh.com/bsf_live_player_testbeeld.html"
        frameborder="0"
        width="560"
        height="315"
      ></iframe>
    </section>
  </main>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import WindSpeedCard from "@/components/WindSpeedCard";
import WindDirectionCard from "@/components/WindDirectionCard";
import WaveCard from "@/components/WaveCard";
import WaterTemperatureCard from "@/components/WaterTemperatureCard";
// import TidesCard from "@/components/TidesCard.vue";
import WindSpeedLastHourCard from "@/components/WindSpeedLastHourCard";
import WindDirectionLastHourCard from "@/components/WindDirectionLastHourCard";

import { useWindStore } from "@/store/useWindStore.js";
import { mapActions, mapState } from "pinia";

export default defineComponent({
  name: "TrailerView",
  props: {},
  components: {
    WindSpeedCard,
    WindDirectionCard,
    WaveCard,
    WaterTemperatureCard,
    // TidesCard,
    WindSpeedLastHourCard,
    WindDirectionLastHourCard,
  },
  data() {
    return {
      //   Tides Graph
      tidesData: null,
      refetching: null,
    };
  },
  computed: {
    ...mapState(useWindStore, ["getTrailer"]),
    ...mapState(useWindStore, ["getWaterData"]),
    ...mapState(useWindStore, ["isFetched"]),
  },
  methods: {
    ...mapActions(useWindStore, ["fetchData"]),
    reFetchData() {
      // TODO: fetch when browser /tab is active!
      this.refetching = setInterval(() => {
        this.fetchData();
      }, 10000);
    },
    // async assignSpeedLastHour() {
    //     this.windSpeedLastHourData = await this.getLocation().windSpeedLastHourData;
    // },
  },
  created() {
    this.fetchData();
  },
  async mounted() {
    this.reFetchData();
    // await this.assignSpeedLastHour();
  },
  beforeDestroy() {
    clearInterval(this.refetching);
  },
  setup() {},
});
</script>
