<template>
  <div class="card" :class="{ 'card-empty': noData }">
    <h2>Golfhoogte &amp; -periode</h2>
    <template v-if="!noData">
      <div class="wave-height">
        <img :src="waveHeightIcon" alt="" />
      </div>
      <p>
        <b>Hoogte: {{ waveHeight }} cm</b><br />
        <b>Periode: {{ wavePeriode }} s</b>
      </p>
    </template>
    <EmptyCardBody v-else />
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import EmptyCardBody from "@/components/EmptyCardBody.vue";

export default defineComponent({
  name: "WaveCard",
  props: {
    waveHeightIcon: String,
    waveHeight: Number,
    wavePeriode: Number,
    noData: Boolean,
  },
  components: {
    EmptyCardBody,
  },
  setup() {},
});
</script>
