<template>
  <section>
    <h2>Getijden</h2>
    <div
      class="card card-secondary"
      :class="loaded ? 'card-wave-time' : 'card-empty'"
    >
      <template v-if="loaded">
        <!-- :time-stamps="timeStamps" -->
        <LineChart
          ref="tidesChart"
          :chart-data="chartData"
          :chart-options="chartOptions"
          :plugins="chartPlugins"
          :height="chartHeight"
        />

        <div class="card-footer">
          <div class="card-col">
            <template v-for="(today, i) in getTodaysData()">
              <dl :key="i" v-if="i > 0">
                <dt>{{ today.stage === "HT" ? "Hoog" : "Laag" }}</dt>
                <dd>
                  <span>{{
                    Intl.DateTimeFormat([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(today.datetime))
                  }}</span
                  ><span>{{ today.rise }} m</span>
                </dd>
              </dl>
            </template>
          </div>

          <div class="card-col">
            <template v-for="(tomorrow, i) in getTomorrowsData()">
              <dl :key="i" v-if="i < getTomorrowsData().length - 1">
                <dt>{{ tomorrow.stage === "HT" ? "Hoog" : "Laag" }}</dt>
                <dd>
                  <span>{{
                    Intl.DateTimeFormat([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(tomorrow.datetime))
                  }}</span
                  ><span>{{ tomorrow.rise }} m</span>
                </dd>
              </dl>
            </template>
          </div>

          <!-- <div class="card-col js-today-am">
            <dl>
              <dt>Hoog</dt>
              <dd>
                <span class="js-time">{{ getTodaysData().x }}</span
                ><span class="js-rise">4,5 m</span>
              </dd>
            </dl>
            <dl>
              <dt>Laag</dt>
              <dd>
                <span class="js-time">7:53 AM</span
                ><span class="js-rise">0,7 m</span>
              </dd>
            </dl>
          </div>
          <div class="card-col js-today-pm">
            <dl>
              <dt>Hoog</dt>
              <dd>
                <span class="js-time">1:12 AM</span
                ><span class="js-rise">4,5 m</span>
              </dd>
            </dl>
            <dl>
              <dt>Laag</dt>
              <dd>
                <span class="js-time">7:53 AM</span
                ><span class="js-rise">0,7 m</span>
              </dd>
            </dl>
          </div>
          <div class="card-col js-tomorrow-am">
            <dl>
              <dt>Hoog</dt>
              <dd>
                <span class="js-time">1:12 AM</span
                ><span class="js-rise">4,5 m</span>
              </dd>
            </dl>
            <dl>
              <dt>Laag</dt>
              <dd>
                <span class="js-time">7:53 AM</span
                ><span class="js-rise">0,7 m</span>
              </dd>
            </dl>
          </div>
          <div class="card-col js-tomorrow-pm">
            <dl>
              <dt>Hoog</dt>
              <dd>
                <span class="js-time">1:12 AM</span
                ><span class="js-rise">4,5 m</span>
              </dd>
            </dl>
            <dl>
              <dt>Laag</dt>
              <dd>
                <span class="js-time">7:53 AM</span
                ><span class="js-rise">0,7 m</span>
              </dd>
            </dl>
          </div> -->
        </div>
      </template>

      <div class="card-body" v-else>
        <img src="@/assets/svgstore/unavailable-data.svg" alt="" />
        <p class="empty-message">Data niet beschikbaar</p>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import { Line as LineChart } from "vue-chartjs/legacy";
import { defaults } from "chart.js";
// import 'chartjs-adapter-moment';
import "chartjs-adapter-luxon";

export default defineComponent({
  name: "TidesCard",
  components: { LineChart },
  props: {
    tidesData: Array,
    isTidesDataFetched: Boolean,
  },
  data: (props) => ({
    loaded: props.isTidesDataFetched,
    chartData: {},
    chartDataTomorrow: {},
    chartOptions: {},
    chartPlugins: [],
    timeStampOptions: { hour: "2-digit", minute: "2-digit" },
    dateTimeOptions: {
      month: "2-digit",
      day: "2-digit",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    },
    windowWidth: 0,
    chartHeight: 230,
  }),
  methods: {
    getTodaysData() {
      // Add at the start the last from yesterday
      const today = this.tidesData.filter(
        (t) => new Date(t.datetime).toDateString() === new Date().toDateString()
      );
      const yesterday = this.tidesData.filter(
        (t) =>
          new Date(t.datetime).toDateString() ===
          this.addDays(Date.now(), -1).toDateString()
      );
      const lastYesterday = yesterday[yesterday.length - 1];
      today.unshift(lastYesterday);

      return today;
    },
    getTomorrowsData() {
      // Add at the end the first from the day after tomorrow
      const today = Date.now();

      const tomorrow = this.tidesData.filter(
        (t) =>
          new Date(t.datetime).toDateString() ===
          this.addDays(today, 1).toDateString()
      );
      const dayAfterTomorrow = this.tidesData.filter(
        (t) =>
          new Date(t.datetime).toDateString() ===
          this.addDays(today, 2).toDateString()
      );

      tomorrow.push(dayAfterTomorrow[0]);

      return tomorrow;
    },
    getFlatArrayOfData() {
      const todaysTides = [...this.getTodaysData().map((el) => el.rise)];
      const tomorrowsTides = [...this.getTomorrowsData().map((el) => el.rise)];

      return todaysTides.concat(tomorrowsTides);
    },
    getFlatArrayOfRiseStage() {
      const todaysTides = [...this.getTodaysData().map((el) => el.stage)];
      const tomorrowsTides = [...this.getTomorrowsData().map((el) => el.stage)];

      return todaysTides.concat(tomorrowsTides);
    },
    getFlatArrayOfTimestamps(formatted, onlyToday = false) {
      //   console.log("formatted", formatted);

      const todaysTimestamps = [
        ...this.getTodaysData().map((el) =>
          formatted
            ? Intl.DateTimeFormat([], this.dateTimeOptions).format(
                new Date(el.datetime)
              )
            : el.datetime
        ),
      ];

      const tomorrowsTimestamps = [
        ...this.getTomorrowsData().map((el) =>
          formatted
            ? Intl.DateTimeFormat([], this.dateTimeOptions).format(
                new Date(el.datetime)
              )
            : el.datetime
        ),
      ];

      return onlyToday
        ? todaysTimestamps
        : todaysTimestamps.concat(tomorrowsTimestamps);
    },
    getArrayOfObjects(onlyToday = false) {
      function Dataset(x, y, stage) {
        this.x = x;
        this.y = y;
        this.stage = stage;
      }

      const arrOfObjects = [];

      this.getFlatArrayOfTimestamps(false, onlyToday).map((el, i) => {
        const obj = new Dataset(
          el,
          this.getFlatArrayOfData()[i],
          this.getFlatArrayOfRiseStage()[i]
        );
        arrOfObjects.push(obj);
      });

      //   console.log("test", arrOfObjects);
      return arrOfObjects;
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
  created() {
    this.windowWidth = window.innerWidth;

    if (this.windowWidth > 700) {
      this.chartHeight = 130;
    } else if (this.windowWidth < 470) {
        this.chartHeight = 300;
    }
  },
  async mounted() {
    // console.log("mounted Tides, data:", this.tidesData);
    // this.windowWidth = window.innerWidth;

    defaults.font.family = "Oswald, sans-serif";
    defaults.font.size = 16;
    defaults.font.color = "#000";

    const parsedData =
      this.windowWidth > 830
        ? this.getArrayOfObjects()
        : this.getArrayOfObjects(true);

    const imgUp = new Image();
    const imgDown = new Image();
    imgUp.src = require("@/assets/img/icon-up.svg");
    imgDown.src = require("@/assets/img/icon-down.svg");
    const imgArrowUp = new Image();
    const imgArrowDown = new Image();
    imgArrowUp.src = require("@/assets/img/arrow-up.svg");
    imgArrowDown.src = require("@/assets/img/arrow-down.svg");

    this.chartData = {
      // labels: this.getFlatArrayOfTimestamps(true),
      datasets: [
        {
          // data: this.getFlatArrayOfData(),
          data: parsedData,
          backgroundColor: "#fff",
          borderColor: "#84C9DC",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
          spanGaps: true,
          pointHitRadius: 24,
          pointRadius: 0,
          pointBorderWidth: 0,
          pointHoverRadius: 0,
          pointHoverBorderWidth: 0,
          pointBackgroundColor: "#84C9DC",
          pointHoverBackgroundColor: "#84C9DC",
          pointBorderColor: "white",
          pointHoverBorderColor: "white",
          //   pointStyle: 'triangle',
          pointRotation: 180,
        },
      ],
    };

    // lineImages Plugin
    const lineImage = {
      id: "lineImage",
      beforeDatasetDraw: (
        chart //args, options
      ) => {
        const { ctx } = chart;

        ctx.save();

        for (let i = 0; i < chart.getDatasetMeta(0).data.length; i++) {
          const element = chart.getDatasetMeta(0).data[i];

          i % 2 === 0
            ? ctx.drawImage(imgDown, element.x - 10, element.y - 25, 20, 20)
            : ctx.drawImage(imgUp, element.x - 10, element.y + 5, 20, 20);
        }
      },
    };
    this.chartPlugins = [lineImage];

    this.chartOptions = {
      responsive: true,
      animation: false,

      plugins: {
        legend: {
          position: "none",
        },
        title: {
          display: false,
        },
        tooltip: {
          // CUSTOM HTML TOOLTIP:
          // Disable the on-canvas tooltip
          enabled: false,

          external: (context) => {
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;

            // console.log("tooltip", tooltipModel);
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = "0";
              return;
            }

            // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
            tooltipEl.classList.remove("below");

            // Set HTML & Data
            const dataFromCurrentElement = tooltipModel.dataPoints[0];
            //   const currentElement = dataFromCurrentElement.dataIndex;
            const formattedValue = dataFromCurrentElement.formattedValue.trim();
            const currentDataToShow = formattedValue;
            // .substr(1, formattedValue.length - 2)
            // .split(" ");
            const title = Intl.DateTimeFormat([], this.dateTimeOptions).format(
              new Date(dataFromCurrentElement.raw.x)
            );

            if (tooltipModel.body) {
              const innerHtml = `
                        <div style="border-collapse: separate; overflow: hidden; border-radius: 4px; box-shadow: 0 0 6px -2px rgba(0,0,0,.5);">
                            <div style="display: flex; flex-wrap: wrap; flex-direction: column; padding: 8px 12px; background-color: white">
                                <div style="display: flex; font-size: 14px; flex-wrap: nowrap; min-width: 96px; font-weight: 800; line-height: 1.5;">
                                ${title.replaceAll(",", " ")}</div>
                                <div style="display: flex; font-size: 14px; flex-wrap: nowrap; min-width: 96px;">
                                    <span 
                                      style="flex: 0 0 auto; display: block; width: auto; margin-right: 8px;">
                                        ${currentDataToShow} m
                                    </span>
                                    <img 
                                      style="width: 12px; flex: 0 0 12px;"
                                      src="${
                                        dataFromCurrentElement.raw.stage ===
                                        "HT"
                                          ? imgArrowUp.src
                                          : imgArrowDown.src
                                      }" alt="${
                dataFromCurrentElement.raw.stage
              }" >
                                </div>
                            </div>
                         </div>
                    `;

              tooltipEl.querySelector("table").innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";

            if (dataFromCurrentElement.raw.stage === "LT") {
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY +
                4 +
                "px";
            } else {
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY -
                68 +
                "px";
            }

            if (
              dataFromCurrentElement.dataset.data.length - 1 ===
              dataFromCurrentElement.dataIndex
            ) {
              // laatste
              tooltipEl.style.left =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX +
                16 +
                "px";

              tooltipEl.style.transform = "none";
              tooltipEl.style.transform = `translateX(-${
                tooltipEl.querySelector("table").getBoundingClientRect().width
              }px)`;

              //   console.log("laatste", tooltipEl);
            } else if (dataFromCurrentElement.dataIndex === 0) {
              // eerste

              tooltipEl.style.left =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX -
                16 +
                "px";

              tooltipEl.style.transform = "none";
              //   console.log("eerste", tooltipEl);
            } else {
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + "px";

              tooltipEl.style.transform = `translateX(-${
                tooltipEl.querySelector("table").getBoundingClientRect().width /
                2
              }px)`;

              //   console.log("de rest", tooltipEl);
            }
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          },
        },
      },
      scales: {
        y: {
          min: -3.5,
          max: 10,

          grid: {
            drawBorder: false,
            color: "#ccc7",
            lineWidth: 1,
            tickColor: "#84C9DC",
            tickBorderDash: [0, 1],
          },
          ticks: {
            stepSize: 4,
            color: "#333",
            font: {
              family: "Oswald, sans-serif",
              size: 12,
            },
          },
        },
        x: {
          offset: true,
          //   min: this.getArrayOfObjects()[0]["x"],
          //   max: this.getArrayOfObjects()[9]["x"],
          type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "HH",
            },
          },
          ticks: {
            //   source: 'labels',
            major: {
              enabled: true,
            },

            font: (context) => {
              //   console.log(context);
              const bolded = context.tick && context.tick.major ? "bold" : "";
              const size = context.tick && context.tick.major ? 16 : 14;
              return {
                family: "Oswald, sans-serif",
                weight: bolded,
                size: size,
              };
            },
            callback: (value, index, values) => {
              //   const no = false;
              //   if (no)
              //   console.log("ticks", value, index, values);
              let tickText = [`00 h`, `${value}`];
              if (!values[index].major) {
                tickText = `${value} h`;
              }

              //   if (index % 8 === 0) {
              //       return new Intl.DateTimeFormat("en-GB", {
              //         hour: "2-digit",
              //         minute: "2-digit",
              //       }).format(new Date(values[index].value));
              //   }
              //   if (values[index].major) {
              //       return new Intl.DateTimeFormat("en-GB", {
              //         day: "2-digit",
              //         month: "2-digit",

              //       }).format(new Date(values[index].value));
              //   }
              if (value % 6 === 0 || values[index].major) return tickText;
            },
          },

          grid: {
            color: "#0000",
            drawBorder: false,
          },
          //   display: function(context) {
          //       console.log(context.scale)
          //   },
        },
      },
    };

    this.getArrayOfObjects();
    // window.addEventListener("resize", resizeListener);

    // console.log("getTodaysData", this.getTodaysData());
    // console.log("getTomorrowsData", this.getTomorrowsData());
  },
  updated() {
    this.$nextTick(() => {
      const chartInstance = this.$refs.tidesChart;
      setTimeout(() => {
        // console.log("next tick", chartInstance);
        chartInstance.updateChart();
      }, 1000);
    });
  },
});
</script>

<style>
.card-footer {
  flex: 1 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 20px;
}

.card-col {
  flex: 1 0 25%;
  /* outline: 1px solid; */
  font-size: 14px;

  padding: 4px 16px;

  font-size: 14px;

  /* max-width: 50%; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: 64px;
}

.card-col:nth-child(n + 2) {
  display: none;
}

dl {
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 1.7; */
}

dt {
  font-weight: 800;
  margin-right: 12px;
  width: 44px;
}

dd {
  width: auto;
  min-width: 96px;
  display: flex;
  justify-content: space-between;
}

dd > span:first-child {
  margin-right: 3px;
}

dd > span:first-child {
  margin-right: 3px;
}

@media screen and (min-width: 830px) {
  .card-col {
    max-width: 50%;
  }

  .card-col:nth-child(n + 2) {
    display: flex;
  }
}
</style>
